import React, { useState, useRef, useEffect } from 'react';

import Logo from './Logo';
import ContactButton from '../utils/ContactButton';

function Header(props) {

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [top, setTop] = useState(true);

  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNavOpen || mobileNav.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      if (window.pageYOffset > 10) {
        setTop(false)
      } else {
        setTop(true)
      } 
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);  

  return (
    <header className={`hidden sm:block overflow-hidden fixed w-full z-30 transition duration-300 left-0 ease-in-out border-gray-800 w-screen ${!top && 'bg-black bg-opacity-80 shadow-lg border-b'}`}>
      <div className="max-w-6xl mx-auto" data-aos="fade-down" data-aos-delay="200">

        <div className="justify-between h-20 items-center flex flex-grow flex-wrap ">
          <table>
            <tbody>
              <tr>
                <td>
                  <Logo /> 
                </td>
                <td className="w-8">
                </td>
                <td>
                  <a className="font-standard font-medium text-lg hover:text-gray-300 transition-colors ease-in-out delay-250" href="/guide">Product Guide</a>
                </td>
                <td className="w-8">
                </td>
                <td>
                  <a className="font-standard font-medium text-lg hover:text-gray-300 transition-colors ease-in-out delay-250" href="/about">About</a>
                </td>
                <td className="w-8">
                </td>
                <td>
                  <a className="font-standard font-medium text-lg hover:text-gray-300 transition-colors ease-in-out delay-250" href="/faqs">FAQs</a>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="grid grid-cols-2">
            <a className="font-standard font-medium text-lg text-right items-right mt-2 pr-8 hover:text-gray-300 transition-colors ease-in-out delay-250" href={process.env.REACT_APP_APPLICATION_DOMAIN}>
              Login
            </a>
            <ContactButton short={true} />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
