import React from 'react';
import { withRouter } from 'react-router-dom';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import Arrow from '../utils/Arrow';

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
    }
  }

  createFormDataObj = data => {
    const formData = new FormData();
    Object.keys(data).forEach(k => {
      formData.append(k, data[k]);
    });
    return formData;
  };

  handleSubmit = (e) => {
    e.preventDefault()
    const { name, email, message } = this.state;
    const data = {
      'form-name': 'contact',
      name: name,
      email: email,
      message: message,
    };

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(this.createFormDataObj(data)).toString(),
    })
      .then(() => this.props.history.push('/received'))
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const {name, email, message} = this.state

    return (
      <div className="flex flex-col min-h-screen overflow-hidden bg-black">
        <Header />

        <main className="flex-grow bg-black px-8 w-screen">
          <section className="relative">
            <div className="max-w-2xl mx-auto px-6 relative">
              <div className="pt-20 sm:pt-40 pb-20">
                <div className="mx-auto text-left pb-4">
                  <h1 className="h1 mb-4 font-headings" data-aos="fade-up" data-aos-delay="300">Request an invite <span role="img" aria-label="arrow">➟</span></h1>
                </div>

                <form onSubmit={this.handleSubmit} name="contact" className="mx-auto" data-netlify="true" data-aos="fade-up" data-aos-delay="450">
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <input 
                        id="name" 
                        type="text" 
                        className="form-input w-full text-gray-200 border border-gray-600 bg-gray-900 rounded" 
                        placeholder="Your name" 
                        value={name}
                        onChange={e => this.setState({name: e.target.value})}
                        required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <input 
                        id="email" 
                        type="email" 
                        className="form-input w-full text-gray-200 border border-gray-600 bg-gray-900 rounded" 
                        placeholder="Your best email address" 
                        value={email}
                        onChange={e => this.setState({email: e.target.value})}
                        required />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3">
                      <textarea 
                        id="message" 
                        type="text" 
                        className="form-input w-full text-gray-200 border border-gray-600 bg-gray-900 rounded" 
                        placeholder="Anything else we should know?" 
                        value={message}
                        onChange={e => this.setState({message: e.target.value})}
                        rows="4"/>
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3">
                    <div className="w-full px-3">
                      <button className="btn text-gray-300 bg-gray-900 border border-gray-600 hover:bg-gray-800 hover:border-gray-500">
                        Submit
                        <Arrow />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </main>

        <Footer />

      </div>
    );
  }
}

export default withRouter(Contact);