import React from 'react';

import Header from '../partials/Header';
import Glows from '../utils/Glows';
import HeroHome from '../partials/HeroHome';
import Cta from '../partials/Cta';
import Footer from '../partials/Footer';
import ProductOverview from '../partials/ProductOverview';
import Waves from '../utils/Waves';
import PurpleLine from '../utils/PurpleLine';

export default function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden bg-black w-screen">
      <Header />

      <main className="flex-grow bg-black overflow-hidden px-8 w-screen">
        <div className="hidden sm:block overflow-x-hidden">
          <Waves width="1300" />
          <Glows />
        </div>
        
        <HeroHome />
        <PurpleLine classes="mb-12 max-w-5xl" loadDelay="300" />
        <ProductOverview />
        <Cta />
      </main>

      <Footer />
    </div>
  );
}