import React from 'react';

import ContentLink from '../utils/ContentLink';
import PurpleLine from '../utils/PurpleLine';

export default function ProductGuideDetail() {
  return (
    <section className="pb-12 sm:pb-24">
      <div id="start" />
      <PurpleLine classes="max-w-5xl mb-12 mt-16" loadDelay="250"/>

      <div className="max-w-4xl mx-auto text-base text-gray-300 pb-12" data-aos="fade-down" data-aos-delay="250">
        <h3 className="text-2xl sm:text-3xl font-extrabold font-headings pb-4 text-white" data-aos="fade-down" data-aos-delay="250">Getting started</h3>
        <ol className="list-decimal ml-4 mb-2">
          <li>After you've requested an invite, <b>install the Pocket browser extension</b> in your browser of choice. For all you Google Chrome users, you can find it in the Chrome webstore <ContentLink anchor="here" href="https://chrome.google.com/webstore/detail/save-to-pocket/niloccemoadcdkdjlinkgdfekeahmflj?hl=en" external={true} />.</li>
          <li>If you're approved and receive an email invite from our team, <b>log into</b> the <ContentLink anchor="Fathom app" href="https://app.fathomnetwork.io/" external={true} /> to start tracking your contributions.</li>
        </ol>
        <p>That's it. As you go about your reading and research, simply save the most useful content you come across via the Pocket browser extension. Fathom will automatically sync your saved links. You can track your synced contributions on the <ContentLink anchor="contributions page" href="https://app.fathomnetwork.io/contributions" external={true} /> in the Fathom app.</p>

      </div>

      <div id="referrals" />

      <div className="max-w-4xl mx-auto text-base text-gray-300" data-aos="fade-down" data-aos-delay="250">
        <h3 className="text-2xl sm:text-3xl font-extrabold font-headings pb-4 text-white" data-aos="fade-down" data-aos-delay="250">Inviting others</h3>
        <p className="mb-2">Fathom is invite-only. You can submit new referrals from the 'My Referrals' page in the Fathom app. Each member gets to refer up to 3 people. You and your referrals become part of a guild of sorts with the following benefits:</p>
        <ol className="list-decimal ml-4">
          <li>You will receive 5% of your referrals' lifetime earnings (recursively accounting for whomever they invite as well).</li>
          <li>You also will receive a 15% incremental earnings boost if your friends are as productive as you (both with respect to their contributions, and with repsect to referring new members).</li>
          <li>The 3 friends whom you refer will also receive a portion of your earnings: 10% split amongst them.</li>
        </ol>
      </div>
    </section>
  );
}
