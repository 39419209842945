import React from 'react';
import Arrow from '../utils/Arrow';

export default function HeroFAQs(props) {
  return (
    <section className="relative">
      <div className="relative max-w-3xl mx-auto">
        <div className="pt-20 sm:pt-48">
          <div className="relative w-full justify-center items-center">
            <h1 className="text-3xl sm:text-6xl font-extrabold font-headings" data-aos="fade-down" data-aos-delay="250">Frequently asked questions</h1>
            <ul className="text-gray-400 mt-8" data-aos="fade-down" data-aos-delay="300">
              {props.content.map((section) => (
                <li className="mb-2">
                  <a className="text-gray-100 hover:text-gray-400 flex align-bottom inline-block items-center" href={'#' + section.id}>
                    <Arrow rotate={true} />
                    <span className="ml-2">{section.title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

    </section>
  );
}