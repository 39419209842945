import React from 'react';

import Header from '../partials/Header';
import HeroFAQs from '../partials/HeroFAQs';
import Footer from '../partials/Footer';
import FAQsAccordion from '../partials/FAQsAccordion';
import Cta from '../partials/Cta';
import Waves from '../utils/Waves';
import Glows from '../utils/Glows';
import EmailLink from '../partials/EmailLink';
import TwitterLink from '../partials/TwitterLink';
import DiscordLink from '../partials/DiscordLink';
import ContentLink from '../utils/ContentLink';
import PurpleLine from '../utils/PurpleLine';

const content = [
  {
    title: 'Key concepts',
    id: 'concepts',
    questions: [
      {
        question: "Why decentralize content creation?",
        answer: <div className="text-base text-gray-300">
          <p className="mb-2">Fathom is a platform for bottoms-up educational content creation and curation. Our goals are to:</p>

          <ol className="list-decimal ml-4 mb-2">
            <li><b>Accelerate</b>: Enable the creation of courses/curricula for cutting edge topics &hellip; while they're still cutting edge.</li>
            <li><b>Personalize</b>: Produce decentralized courses that are personalized, not one-size-fits-all.</li>
            <li><b>Share</b>: Supply tools and shared repositories for learners and educators that reduce duplicative work and surface the best material to the community.</li>
          </ol>

          <p>See our <ContentLink href="/about" anchor="About page" /> for more detailed thoughts on this topic.</p>
        </div>
      },
      {
        question: "What is a learning path?",
        answer: <p className="text-base text-gray-300">A learning path is the in-order list of content you consumed (and found helpful) while learning. Successful learning paths can serve as a syllabus of sorts for people who follow in your footsteps in learning about the same topics. One of the hardest parts of learning something new is figuring out where to start and designing a learning plan without the benefit of domain knowledge. Learning paths created by past learners pave the way and reduce that barrier to entry.</p>
      },
      {
        question: "What is Fathom's output?",
        answer: <div className="text-base text-gray-300">
          <p className="mb-2">Fathom is currently focused on creating curated canons. Fathom will aggregate the links and materials you contribute to create artifacts like the following:</p>
          <ol className="list-decimal ml-4 mb-2">
            <li>A16Z Crypto Canon: <ContentLink href="https://a16z.com/2018/02/10/crypto-readings-resources/" external={true} anchor="here" /></li>
            <li>A16Z DAO Canon: <ContentLink href="https://future.com/dao-canon/" external={true} anchor="here" /></li>
            <li>Github "Awesome" Reading List for ML Systems: <ContentLink href="https://jeongseob.github.io/readings_mlsys.html" external={true} anchor="here" /></li>
          </ol>

          <p className="mb-2">These high quality, curated lists lower the barrier of entry for new learners.</p>
        </div>
      },
      {
        question: "How do I propose a new course topic?",
        answer: <div className="text-base text-gray-300">
          <p className="mb-2">Founding members propose new course topics by doing one of the following:</p>
          <ol className="list-decimal ml-4">
            <li>Post to <DiscordLink /> or <TwitterLink /> to publically suggest a new course. If community support swells around a suggestion, the Fathom team will prioritize it.</li>
            <li>Email the Fathom team directly at <EmailLink />.</li>
          </ol>
        </div>
      },
    ]
  },
  {
    title: 'Product guide',
    id: 'product',
    questions: [
      {
        question: "How are my contributions valued?",
        answer: <div className="text-base text-gray-300">
          <p className="mb-2">The Fathom team and trusted advisors directly assign value to each contribution during our weekly contributions assessment process. We then divide the value of each contribution by the number of Fathom network members who contributed that link within the alloted attribution window (the delay period during which we accumulate contributions before posting and distributing them for consumption).</p>

          <p className="mb-2">Attribution is then assigned based on a combination of your reputation score and the normalized value of your contributions. Higher reputation scores earn you a premium, whereas lower scores discount rewards. </p>

          <p className="mb-2">Reputation scores accrue based on:</p>
          <ol className="list-decimal ml-4">
            <li>The number of recommendations you submit.</li>
            <li>The average value score of your recomendations relative to the broader average across Fathom's member base.</li>
          </ol>

          <p className="mb-2">You maximize your reputation by making a large number of consistently high-quality contributions.</p>
        </div>
      },
      {
        question: "How does Fathom's referral program work?",
        answer: <div className="text-base text-gray-300">
          <p className="mb-2">You can submit new referrals from the 'My Referrals' page in the Fathom app. Each member gets to refer up to 3 people. You and your referrals become part of a guild of sorts with the following benefits:</p>
          <ol className="list-decimal ml-4">
            <li>You will receive 5% of your referrals' lifetime earnings (recursively accounting for whomever they invite as well).</li>
            <li>You also will receive a 15% incremental earnings boost if your friends are as productive as you (both with respect to their contributions, and with repsect to referring new members).</li>
            <li>The 3 friends whom you refer will also receive a portion of your earnings: 10% split amongst them.</li>
          </ol>
        </div>
      },
      {
        question: "How do I provide feedback and/or connect with the Fathom dev team?",
        answer: <p className="text-base text-gray-300">Please connect with our team on <DiscordLink /> or <TwitterLink />. You can also email us at <EmailLink />.</p>
      },
    ]
  },
]

export default function FAQs() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden bg-black">
      <Header />

      <main className="max-w-full overflow-x-hidden px-8 w-screen">
        <div className="hidden sm:block overflow-x-hidden">
          <Waves width="1450" />
          <Glows />
        </div>

        <HeroFAQs content={content} />

        {content.map((section) => (
          <>
            <div id={section.id} />
            <PurpleLine classes="mb-12 mt-16 max-w-4xl" loadDelay="300" />
            <FAQsAccordion title={section.title} faqs={section.questions} />
          </>
        ))}
        <div className="pb-12 sm:pb-20" />

        <Cta />
      </main>

      <Footer />
    </div>
  );
}
