import React from 'react';
import Socials from './Socials';

const navigation = {
  main: [
    { name: 'Home', href: '/' },
    { name: 'Product Guide', href: '/guide' },
    { name: 'About', href: '/about' },
    { name: 'FAQs', href: '/faqs' },
    { name: 'Terms Of Service', href: '/tos' },
    { name: 'Privacy Policy', href: '/privacy' },
  ]
}

export default function Footer() {
  return (
    <footer className="bg-black px-8" data-aos="fade-up" data-aos-delay="200">
      <div className="max-w-6xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8 border-t border-gray-800">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2" data-aos="fade-up" data-aos-delay="350">
              <a href={item.href} className="text-gray-200 hover:text-gray-400 font-standard font-medium transition-colors ease-in-out delay-250">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-4 flex justify-center space-x-6" data-aos="fade-up" data-aos-delay="300" >
          <Socials />
        </div>
        <p className="mt-4 text-center text-gray-500">Learn and leave a path for others to follow. <br/> &copy; 2022 Fathom. All rights reserved.</p>
      </div>
    </footer>
  )
}
