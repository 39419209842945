import React from 'react';

function Glows() {
  return (
    <svg className="absolute top-0 right-0 transform translate-x-1/2 -mr-16" width="800" height="502" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="400" cy="102" r="500" fill="url(#heroglow_paint0_radial)" fillOpacity=".5" />
      <circle cx="209" cy="289" r="200" fill="url(#heroglow_paint1_radial)" fillOpacity=".13" />
      <defs>
        <radialGradient id="heroglow_paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90 149 251) scale(315.089)">
          <stop stopColor="#262b96" />
          <stop offset="1" stopColor="#262b96" stopOpacity=".01" />
        </radialGradient>
        <radialGradient id="heroglow_paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90 -40 249) scale(133.913)">
          <stop stopColor="#97266D" />
          <stop offset="1" stopColor="#97266D" stopOpacity=".01" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default Glows;
