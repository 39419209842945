import React from 'react';
import { Link } from 'react-router-dom';

function Logo() {
  return (
  	<Link to="/" className="flex" aria-label="Home">
      <img src={require("../assets/svg/logo.svg")} className="w-8 h-8" alt="Fathom" />
      <h4 className="pl-4 self-start h4 text-white font-headings" style={{}}>Fathom</h4>
  	</Link>
  );
}

export default Logo;