import React from 'react';

import Header from '../partials/Header';
import HeroProductGuide from '../partials/HeroProductGuide';
import ProductGuideDetail from '../partials/ProductGuideDetail';
import Footer from '../partials/Footer';
import Cta from '../partials/Cta';
import Glows from '../utils/Glows';
import Waves from '../utils/Waves';

export default function Instructions() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden bg-black">
      <Header />

      <main className="flex-grow px-8 w-screen">
        <div className="hidden sm:block overflow-x-hidden">
          <Waves width="1350" />
          <Glows />
        </div>
        <HeroProductGuide />
        <ProductGuideDetail />
        <Cta />
      </main>

      <Footer />
    </div>
  );
}

