import React from 'react';
import ContactButton from '../utils/ContactButton';
import PurpleText from '../utils/PurpleText';

function HeroHome() {

  return (
    <section>
      <div className="max-w-6xl mx-auto mb-10">
        <div className="pt-20 sm:pt-32 pb-10 md:pt-40 md:pb-16">
          <div className="max-w-3xl mx-auto text-center">
            <a href="https://www.fathomnetwork.io/faqs">
              <img src={require("../assets/svg/logo.svg")} className="mx-auto mb-8 w-20 h-20" alt="Fathom" data-aos="fade-down" data-aos-delay="200" />
            </a>
            <h1 className="text-5xl sm:text-6xl font-extrabold mb-2 font-headings font-extrabold text-center" data-aos="fade-down" data-aos-delay="250"><PurpleText text="Fathom" /></h1>
            <h2 className="text-2xl sm:text-3xl font-extrabold mb-10 font-headings font-medium text-center" data-aos="fade-down" data-aos-delay="300">Contribute by learning and leaving a path for others to follow.</h2>
            <div data-aos="fade-down" data-aos-delay="350">
              <ContactButton />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
