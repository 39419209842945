import React from 'react';

function SocialLink(props) {
  return (
    <a className="flex justify-center items-center text-white hover:bg-gray-800 w-12 h-12 rounded-full transition-colors ease-in-out delay-250" href={props.url} target={props.external && "_blank"} rel="noopener noreferrer" aria-label={props.label}>
        <img src={require("../assets/svg/" + props.svg)} className="w-8 h-8" alt={props.label} />
    </a>
  )
}

export default SocialLink;
