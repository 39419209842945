import React from 'react';

export default function HeroProductGuide(props) {
  return (
    <section className="relative">
      <div className="relative max-w-3xl mx-auto">
        <div className="pt-20 sm:pt-44">
          <div className="relative w-full text-center">
            <h1 className="text-4xl sm:text-6xl font-extrabold font-headings" data-aos="fade-down" data-aos-delay="250">Product guide</h1>
          </div>
        </div>
      </div>
    </section>
  );
}