import React from 'react';

export default function ProductOverview() {

  return (
    <div className="relative sm:pt-8 overflow-hidden max-w-4xl mx-auto pb-12 sm:pb-24">
      <h2 className="text-3xl sm:text-4xl font-extrabold font-headings text-center mx-auto mb-12 sm:mb-20" data-aos="fade-down" data-aos-delay="200">How it works</h2>

      <div className="hidden sm:block">
        <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 grid-flow-col-dense gap-24 pb-24">
          <div className="mt-8">
            <img src={require("../assets/svg/sync.svg")} className="w-12 h-12 mb-4" alt="Sync" data-aos="fade-right" data-aos-delay="250"/>
            <h3 className="h3 font-headings" data-aos="fade-right" data-aos-delay="250">Connect to Pocket</h3>
            <p className="mt-4 text-lg text-gray-300 mb-4" data-aos="fade-right" data-aos-delay="300">
              Login and sync your Pocket account with Fathom. This enables Fathom to retrieve your contributions automatically going forward.
            </p>
          </div>
          <img src={require("../assets/img/connect-pocket.png")} className="mx-auto mb-4 w-100 h-100" alt="Cash" data-aos="fade-left" data-aos-delay="300"/>
        </div>

        <div className="mx-auto grid grid-cols-2 grid-flow-col-dense gap-24 pb-24">
          <img src={require("../assets/img/save-article.png")} className="mx-auto mb-4 w-100 h-100" alt="Cash" data-aos="fade-right" data-aos-delay="300"/>
          <div>
            <div className="mt-8">
              <img src={require("../assets/svg/pocket.svg")} className="mb-4 w-12 h-12" alt="Pocket" data-aos="fade-left" data-aos-delay="200"/>
              <h3 className="h3 font-headings" data-aos="fade-left" data-aos-delay="250">Save articles</h3>
              <p className="mt-4 text-lg text-gray-300" data-aos="fade-left" data-aos-delay="300">
                While you're reading, save the most helpful content you come across to Pocket via the Pocket browser extension.
              </p>
            </div>
          </div>
        </div>

        <div className="mx-auto grid grid-cols-2 grid-flow-col-dense gap-24">
          <div>
            <img src={require("../assets/svg/cash.svg")} className="w-20 h-20" alt="Earn" data-aos="fade-right" data-aos-delay="200"/>
            <h3 className="h3 font-headings" data-aos="fade-right" data-aos-delay="250">Earn attribution</h3>
            <p className="mt-4 text-lg text-gray-300" data-aos="fade-right" data-aos-delay="300">
            That's it. Fathom will sync your Pocket contributions regularly and assign you attribution credits based on the value of your contributions.
            </p>
          </div>
          <img src={require("../assets/img/contributions.png")} className="mx-auto mb-4 w-100 h-100" alt="Cash" data-aos="fade-left" data-aos-delay="300"/>
        </div>
      </div>

      <div className="block sm:hidden">
        <div data-aos="fade-down" data-aos-delay="200">
          <img src={require("../assets/svg/sync.svg")} className="w-8 h-8 mb-4" alt="Sync" data-aos="fade-right" data-aos-delay="250"/>
          <h4 className="h4 font-headings" data-aos="fade-right" data-aos-delay="250">Connect to Pocket</h4>
          <p className="mt-4 text-lg text-gray-300 mb-4" data-aos="fade-right" data-aos-delay="300">
            Login and sync your Pocket account with Fathom. This enables Fathom to retrieve your contributions automatically going forward.
          </p>
        </div>

        <div className="mt-12" data-aos="fade-down" data-aos-delay="250">
          <img src={require("../assets/svg/pocket.svg")} className="mb-4 w-8 h-8" alt="Pocket" data-aos="fade-left" data-aos-delay="200"/>
          <h4 className="h4 font-headings" data-aos="fade-right" data-aos-delay="250">Save articles</h4>
          <p className="mt-4 text-lg text-gray-300" data-aos="fade-left" data-aos-delay="300">
            While you're reading, save the most helpful content you come across to Pocket via the Pocket browser extension.
          </p>
        </div>

        <div className="mt-12" data-aos="fade-down" data-aos-delay="300">
          <img src={require("../assets/svg/cash.svg")} className="w-12 h-12" alt="Earn" data-aos="fade-right" data-aos-delay="200"/>
          <h4 className="h4 font-headings" data-aos="fade-right" data-aos-delay="250">Earn attribution</h4>
          <p className="mt-4 text-lg text-gray-300" data-aos="fade-right" data-aos-delay="300">
            That's it. Fathom will sync your Pocket contributions regularly and assign you attribution credits based on the value of your contributions.
          </p>
        </div>
      </div>
    </div>
  );
}
