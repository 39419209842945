import React from 'react';

import ContentLink from '../utils/ContentLink';
import PurpleLine from '../utils/PurpleLine';

export default function AboutDetail() {
  return (
    <>
      <div id="accelerate" />
      <section className="pb-12">
        <PurpleLine classes="max-w-5xl mb-12 mt-16" loadDelay="250"/>

        <div className="max-w-4xl mx-auto text-base text-gray-300" data-aos="fade-down" data-aos-delay="250">
          <h3 className="text-2xl sm:text-3xl font-extrabold font-headings pb-4 text-white" data-aos="fade-down" data-aos-delay="250">Accelerate</h3>

          <p className="mb-2">Accelerate the rate at which teaching and learning material forms for and around domains on the cutting edge. Typically, by the time formal course material is published for a topic, the topic is mature and no longer cutting edge. Until then, curious pioneers have to teach themselves. As an extreme but illustrative example, there is arguably no proper full and contemporary course on ZK-STARKs, StarkWare Cairo, and Practical Probabilistically Checkable Proofs (PCPs) for Blockchain Scaling and Delegated Computing. There are related materials such as various lectures, docs, wikipages for subtopics, etc floating around, but they have yet to be assembled into a more structured, guided course.</p>

          <p className="mb-2">More generally, we and our hypercurious friends struggle to find courses to satisfy our personal and professional curiosity w.r.t. the cutting edge of, e.g.:</p>
          <ul className="list-disc ml-4 mb-2">
            <li>Clean Energy Storage Technology</li>
            <li>Carbon Capture</li>
            <li>Practical Quantum Computing with Today’s Noisy Systems</li>
            <li>Peer-to-Peer Distributed Systems</li>
            <li>Large Language Models and ML Systems at Scale</li>
            <li>Small Data Regime ML and Sample-Efficiency Critical RL</li>
            <li>Mechanism Design and Opensource/Crypto Governance</li>
          </ul>

          <p className="mb-2">To double click on a single example, a course discussing the key ideas undergirding opensource governance and examining case studies spanning crypto protocols, opensource software (Linux, Hadoop, Android), projects such as Wikipedia, etc would be intellectually intriguing and practically informative. Howevever, no thorough and up-to-date course exists.</p>
        </div>

        <div id="personalize" />
        <PurpleLine classes="max-w-5xl mb-12 mt-16" loadDelay="250"/>

        <div className="max-w-4xl mx-auto text-base text-gray-300" data-aos="fade-down" data-aos-delay="250">
          <h3 className="text-2xl sm:text-3xl font-extrabold font-headings pb-4 text-white" data-aos="fade-down" data-aos-delay="250">Personalize</h3>

          <p className="mb-2">A lot of education is compulsory, so educators don’t always have to agonize over how to make the material engaging and accessible. Educational material is often a <ContentLink href="https://www.britannica.com/topic/Procrustes" external={true} anchor="Bed-of-Procrustes" />.</p>

          <p className="mb-2">Educators in higher-ed settings often can make strong assumptions about the prerequisite material that the students know and can restrict access to courses on this basis.</p>
          <p className="mb-2"><b>Bottoms-up course creation schemes</b> could more effectively surface the often-implicit knowledge dependency tree:</p>
          <ul className="list-disc ml-4 mb-2">
            <li>e.g., ZK-STARKs are a spire atop a vast structure consisting of deep ideas from the vanguard of complexity theory, cryptography, and beyond.</li>
            <li>To choose a simpler example, though, let’s briefly examine ML. To master ML, one must learn a respectable amount about: Linear Algebra, Probability and Information Theory, Numerical Computation, Convex Optimization (not even to mention all of the different regimes within ML).</li>
          </ul>
          <p className="mb-2">Furthermore, Fathom learning can be better targeted to specific learning goals. To return to our ZK-STARKs example, a prospective course could be intended for:</p>
          <ul className="list-disc ml-4 mb-2">
            <li><b>Practitioners</b> who plan to build decentralized systems (my go-forward term for open-sourced/crowdsourced projects) on top of today's tools, such as StarkNet.</li>
            <li><b>Investors</b> (large holders of ETH or a hypothetical future StarkWare token) who want to understand the technology and where it fits within the broader picture.</li>
            <li><b>Scientists and Researchers</b> endeavoring to understand the underlying math and tech so we can push it forward and expand it to new applications on the frontier (e.g. Advanced Machine Learning on StarkNet).</li>
          </ul>
          <p className="mb-2">→ Each potential course would share a great deal of foundation, but the details, depth, and emphasis across dimensions of the undergirding material would vary greatly. In a bottoms-up and decentralized course creation-scheme, many niche goals can be targeted by matching people to the appropriate learning path.</p>
        </div>

        <div id="share" />
        <PurpleLine classes="max-w-5xl mb-12 mt-16" loadDelay="250"/>

        <div className="max-w-4xl mx-auto text-base text-gray-300" data-aos="fade-down" data-aos-delay="250">
          <h3 className="text-2xl sm:text-3xl font-extrabold font-headings pb-4 text-white" data-aos="fade-down" data-aos-delay="250">Share</h3>

          <p className="mb-2">“<i>Composability is to software as compound interest is to finance</i>” - Chris Dixon</p>

          <p className="mb-2">Anyone who has been a TA or knows a teacher understands how much time teachers spend creating class notes, drills, assignments, practice problems, tests, etc. Many of these are siloed. An ideal market mechanism would enable sharing and would surface the absolute best resource for each learning goal.</p>

          <p className="mb-2">It would also allow a broad swath of people to collaborate on a shared tree, with macro-contributions of full-braches (e.g. ML subcourse on the most important ideas from probability to understand for Deep Learning) and micro-contributions (a single case study example regarding the distributed systems architecture of BitTorrent for “The Computer Science of Web3”.</p>
        </div>

      </section>
    </>
  );
}
