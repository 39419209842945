import React from 'react';
import ContactButton from '../utils/ContactButton';

function Cta() {
  return (
    <section>
      <div className="max-w-6xl mx-auto overflow-hidden">
        <div className="pb-12 md:pb-20">
          <div className="bg-gradient-to-r from-indigo-800 via-purple-800 to-pink-800 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl" data-aos="zoom-y-out" data-aos-delay="450">
            <div className="flex flex-col lg:flex-row justify-between items-center">
              <div className="pl-6 text-left">
                <h3 className="h3 text-white mb-8 font-headings font-medium">Want to contribute?</h3>
                <ContactButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cta;
