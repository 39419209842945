import React from 'react';

import PurpleText from '../utils/PurpleText';
import Checkmark from '../utils/Checkmark';
import ContentLink from '../utils/ContentLink';

export default function HeroAbout() {
  return (
    <section className="relative max-w-4xl mx-auto pt-20 sm:pt-48">
      <h1 className="text-4xl sm:text-6xl font-extrabold pb-12 font-headings text-center" data-aos="fade-down" data-aos-delay="250">About <PurpleText text="Fathom" /></h1>
      <div className="pt-6">
        <h2 className="text-3xl sm:text-4xl font-extrabold mb-4 font-headings" data-aos="fade-down" data-aos-delay="350">Learn to earn</h2>

        <ul className="max-w-sm sm:max-w-md mx-auto md:max-w-none text-gray-200 mt-8" data-aos="fade-down" data-aos-delay="400">
          <li className="flex items-center mb-2">
            <Checkmark inverted={true} />
            <span className="ml-2"><b><ContentLink href="#accelerate" anchor="Accelerate" /></b>: Enable the creation of courses/curricula for cutting edge topics &hellip; while they're still cutting edge.</span>
          </li>
          <li className="flex items-center mb-2">
            <Checkmark inverted={true}  />
            <span className="ml-2"><b><ContentLink href="#personalize" anchor="Personalize" /></b>: Produce bottoms-up courses that are personalized, not one-size-fits-all.</span>
          </li>
          <li className="flex items-center mb-2">
            <Checkmark inverted={true}  />
            <span className="ml-2"><b><ContentLink href="#share" anchor="Share" /></b>: Supply a tool to learners and educators that helps to reduce duplicative work and identify the best content.</span>
          </li>
        </ul>
      </div>
    </section>
  );
}
