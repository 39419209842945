import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import Arrow from '../utils/Arrow';
import Waves from '../utils/Waves';

function PageNotFound() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden bg-black">
      <Header />

      <main className="flex-grow px-8 w-screen">
        <div className="hidden sm:block overflow-x-hidden">
          <Waves width="1500" />
        </div>

        <section className="relative">
          <div className="max-w-4xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <div className="text-center">
                <div className="relative inline-flex justify-center items-center">
                  <div className="w-full">
                    <img src={require("../assets/svg/404.svg")} className="mx-auto mb-4 w-100 h-100" alt="Not found" data-aos="fade-down" data-aos-delay="250"/>
                    <h3 className="h3 font-headings mb-4" data-aos="fade-down" data-aos-delay="300">This is not the page you are looking for <span role="img" aria-label="waive">✌️</span></h3>
                    <p className="text-xl font-standard mb-8" data-aos="fade-down" data-aos-delay="300">There isn't a page at this location. Please double check the URL's spelling.</p>
                    <Link data-aos="fade-down" data-aos-delay="350" className="btn font-standard font-medium text-black bg-white hover:bg-gray-200 inline-flex items-center" to="/">
                      <span>Back home</span>
                      <Arrow />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default PageNotFound;