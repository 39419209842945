import React from 'react';

import SocialLink from '../utils/SocialLink';

function Socials() {
  return (
    <ul className="flex flex-wrap font-medium justify-center">
      <li className="ml-4">
        <SocialLink url={"mailto:" + process.env.REACT_APP_CONTACT_EMAIL} external={true} label={"Email"} svg={"mail.svg"} />
      </li>
      <li className="ml-4">
        <SocialLink url={process.env.REACT_APP_DISCORD_URL} external={true} label={"Discord"} svg={"discord.svg"} />
      </li>
      <li className="ml-4">
        <SocialLink url={process.env.REACT_APP_TWITTER_URL} external={true} label={"Twitter"} svg={"twitter.svg"} />
      </li>
    </ul>
  );
}

export default Socials;
