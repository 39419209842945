import React from 'react';
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function FAQsAccordion(props) {
  return (
    <div className="max-w-6xl mx-auto">
      <div className="max-w-3xl mx-auto divide-y-2 divide-gray-800">
        <h3 className="text-2xl sm:text-3xl font-extrabold font-headings" data-aos="fade-down" data-aos-delay="250">{props.title}</h3>
        <dl className="mt-6 space-y-6 divide-y divide-gray-800" data-aos="fade-down" data-aos-delay="300">
          {props.faqs.map((faq) => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-white">
                      <span className="font-medium text-white " data-aos="fade-down" data-aos-delay="250">{faq.question}</span>
                      <span className="ml-6 h-7 flex items-center">
                        <ChevronDownIcon
                          className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform transition ease-in-out delay-200')}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    {faq.answer}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  )
}